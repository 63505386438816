<template>
  <v-container>
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Zmiana hasła</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field outlined dense v-model="oldPassword" label="Stare hasło" :type="showOP ? 'text' : 'password'"
                       :append-icon="showOP ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showOP = !showOP"/>
        <v-text-field outlined dense v-model="newPassword" label="Nowe hasło" :type="showNP ? 'text' : 'password'"
                      :append-icon="showNP ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showNP = !showNP"/>
        <v-text-field outlined dense v-model="confirmPassword" label="Nowe hasło" :type="showCP ? 'text' : 'password'"
                      :append-icon="showCP ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showCP = !showCP"/>
        <v-btn color="primary" @click="changePassword()">
          <v-icon left>mdi-lock-reset</v-icon>
          ZMIEŃ HASŁO
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AdminSettings',
  data: () => ({
    oldPassword: '', newPassword: '', confirmPassword: '', showOP: false, showNP: false, showCP: false
  }),
  methods: {
    changePassword() {
      if (this.newPassword !== this.confirmPassword)
        return window.alert('Hasła nie są identyczne');
      if (this.newPassword.length < 3)
        return window.alert('Hasło musi mieć przynajmniej 3 znaki');
      this.$http.post('/api/admin/auth/change-password', {
        oldPassword: this.oldPassword, newPassword: this.newPassword
      }).then(() => {
        window.alert('Hasło zostało zmienione');
      }).catch(() => {
        window.alert('Wystąpił błąd podczas zmiany hasła');
      });
    }
  }
};
</script>
